export const lang = {
  swap1: '闪兑',
  swap2: '立刻开始闪兑',
  swap3: 'from',
  swap4: 'balance: ',
  swap5: '选择币种',
  swap6: 'max',
  swap7: '价格',
  swap8: '滑点容差',
  swap9: '连接钱包',
  swap10: '输入数量',
  swap11: '余额不足',
  swap12: '此交易的流动性不足',
  swap13: '选择代币',
  swap14: '授权',
  swap15: '包装',
  swap16: '解包',
  swap17: '闪兑',
  swap18: '交易收据',
  swap19: '正在等待确认',
  swap20: '正在将',
  swap21: '交换为',
  swap22: '在你的钱包种确认此交易',
  swap23: '已提交交易',
  swap24: '在BSC上查看',
  swap25: '关闭',
  swap26: '交易拒绝',
  swap27: '确认交换',
  swap28: '输出为估值,您将获得至少{inputOutMin} {symbol},或者交易将被撤回',
  swap29: '输入为估值,您将出售最多{inputInMax} {symbol},或者交易将被撤回',
  swap30: '最小获得量',
  swap31: '最大出售量',
  swap32: 'to',
  swap33: '管理代币',
  swap34: '导入代币',
  swap35: '导入',
  swap36: '管理Tokens',
  swap37: '自定义代币',
  swap38: '全部清除',
  swap39: '搜索名称或粘贴地址',
  swap40: '设置',
  swap41: '默认交易速度(GWEI)',
  swap42: '标准',
  swap43: '快速',
  swap44: '即时',
  swap46: '输入有效的滑点百分比',
  swap47: '你的交易可能会失败',
  swap48: '你的交易可能会被超前',
  swap49: '交易截止时间(分钟)',
  swap50: '估计',
  swap51: '禁用多跳',
  swap52: '最近交易',
  swap53: '最近没有交易',
  swap54: '全部清除',
  swap55: '链接钱包',
  swap56: '加入董事会',
  swap57: '捐赠',
  swap58: '权益赠送',
  swap59: '董事会成员列表',
  swap60: '邀请',
  swap61: '复制',
  swap62: '我的DAPP邀请人:',
  swap63: '邀请记录',
  swap64: '出错啦,请稍后再试',
  swap65: '功能暂未开放',
  swap66: '领取',
  swap67: '取出成功',
  swap68: '捐赠中,待确认',
  swap69: '已捐赠',
  swap70: '非白名单用户',
  swap71: '捐赠无效',
  swap72: '当前余额不足',
  swap73: '授权中,请稍等',
  swap74: '授权失败',
  swap75: '捐赠中,请稍等',
  swap76: '捐赠失败',
  swap77: '兑换',
  swap78: '流动性',
  swap79: '会员',
  swap80: '邀请',
  swap81: '权益池',
  swap82: '跨链桥',
  swap83: '池子',
  swap84: '农场',
  swap85: '官网',
  swap86: '市场',
  swap87: '兑换',
  swap88: '交易',
  swap89: '赚取',
  swap90: '确认',
  swap91: '价格影响',
  swap92: '最小获得量',
  swap93: '流动性供应商费用',
  swap94: '增加流动性',
  swap95: '增加流动性以接收 LP 代币',
  swap96: '流动性池中的份额',
  swap97: '兑换率和流动池份额',
  swap98: '您的流动性',
  swap99: '移除流动性以收回代币',
  swap100: '未找到流动性',
  swap101: '未看到您加入的流动池？',
  swap102: '查看其他LP代币',
  swap103: '已入池',
  swap104: '移除',
  swap105: '供应',
  swap106: '导入池',
  swap107: '导入现有流动性池',
  swap108: '选择代币以查找您的流动性。',
  swap109: '您在该流动性池中还没有提供流动性。',
  swap110: '您的钱包中的LP代币',
  swap111: '接收',
  swap112: '和',
  swap113: '金额',
  swap114: '你将获得',
  swap115: '输出为估值。如果价格变化超过 {slippage}%,则您的交易将被撤回',
  swap116: '已焚毁',
  swap117: '已入金',
  swap118: '确认供应',
  swap119: '汇率',
  swap120: '您是第一个流动性供应商。',
  swap121: '您添加代币的比列,将决定该流动性池的初始价格。',
  swap122: '如果您对汇率满意,请点击“供应”以进行检查。',
  swap123: '正在移除',
  swap124: '正在供应',
  swap125: '确认供应',
  swap126: '白皮书',
  swap127: '审计',
  swap128: '公告',
  swap129: '提示',
  swap130: 'PHO 1:1 兑换',
  swap131: '更多',
  swap132: '正在加载',
  swap133: '信息',
  swap134: '捐赠名额已满',
  swap135: '我的PHO',
  swap136: '钱包中的PHO',
  swap137: '可收割的PHO',
  swap138: 'PHO价格',
  swap139: 'PHO总量',
  swap140: 'PHO市值',
  swap141: 'PHO信息',
  swap142: '已赚取',
  swap143: '质押',
  swap144: '个人TVL',
  swap145: '社区TVL',
  swap146: '收割',
  swap147: '累计激励 ',
  swap148: '今日激励',
  swap149: '参与市商地址数',
  swap150: '我的邀请链接',
  swap151: '最大算力区',
  swap152: '其他算力区',
  swap153: '我的好友邀请',
  swap154: '取消',
  swap155: '修改邀请人',
  swap156: '邀请人不能为空',
  swap157: '修改成功',
  swap158: '质押LP代币',
  swap159: '赎回LP代币',
  swap160: '赎回',
  swap161: '获取',
  swap162: '当前暂无收益',
  swap163: '路由',
  swap164: '审计机构',
  swap165: '合作伙伴',
  swap166: '发行',
  swap167: '活动',
  swap168: '单币池',
  swap169: 'LP池',
  swap170: '国会',
  swap171: '卡牌',
  swap172: '盲盒',
  swap173: '游戏',
  swap174: '权益池已分配总额',
  swap175: '待领取数额',
  swap176: '我已获取数额',
  swap177: '取出',
  swap178: '超级节点',
  swap179: '权益卡已领取份额',
  swap180: '国库',
  swap181: '国库累计金额',
  swap182: '国库已分配金额',
  swap183: '国库已回购金额',
  swap184: '专家模式',
  swap185: 'D6 Swap是基于OKExChain研发的全网首个股权税收制Defi平台,逐步推出Swap、NFT、Gamefi、Lend、机枪池等板块。面世以来通过领先的技术和公平公开的市场理念,提供更加广泛的资产增值方案和前所未有的体验感,深受广大用户喜爱。',
  swap186: '一键取出',
  swap187: '价格影响过高',
  swap188: 'FAQ',
  swap189: 'NFT',
  swap190: '赚币',
  swap191: '进行中',
  swap192: '已结束',

  swap193: '说明',
  swap194: '股权权益生态卡,全球限量1680张',
  swap195: '点亮股权权益生态卡的要求及收益如下',
  swap196: '一星卡：个人地址拥有价值1000USDT流动性,团队1000USDT流动性,收益共分交易税收0.1%',
  swap197: '二星卡：个人拥有价值2000USDT流动性,团队1万USDT流动性,收益共分交易税收0.1%',
  swap198: '三星卡：个人3000USDT流动性,团队10万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap199: '四星卡：个人拥有价值4000USDT流动性,团队100万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap200: '五星卡：个人5000USDT流动性,团队1000万USDT流动性,设立工作室,收益共分交易税收0.1%',
  swap201: '备注：所有权益生态卡收益全部链上自动分配,权益卡享受交易所所有生态分红,权益卡可传承可交易可转让,满足要求自动升级,不满足要求自动降级及自动退出。',
  swap202: '权益卡',
  swap203: '发展路线图：',
  swap204: '2022年7月  2.0版上线',
  swap205: '2022年9月  开通持币多挖',
  swap206: '2022年12月 NFT交易平台上线',
  swap207: '2023年 6月  Goodbaby游戏上线',
  swap208: '2023年 9月  Goodlend上线',
  swap209: 'D6 Swap介绍：',
  swap210: '滑点：',
  swap211: '分配：',
  swap212: '买入',
  swap213: '卖出',
  swap214: '0.5% LP分红',
  swap215: '0.5% 生态权益卡分红',
  swap216: '0.25% 回流底池销毁LP',
  swap217: '0.25% 生态建设',
  swap218: '暂无奖励领取',
  swap219: '个人TVL',
  swap220: '团队TVL',
  swap221: 'LP数量',
  swap222: '钱包中的pho',
  swap223: 'GDT',
  swap224: 'GDT空投累计奖励',
  swap225: '星级用户累计奖励',
  swap226: '免手续费倒计时',
  swap227: '可取出奖励',
  swap228: 'LP挖矿奖励',
  swap229: '质押pho',
  swap230: '赎回pho',
  swap231: '目标共识',
  swap232: '已达共识',
  swap233: '已共识',
  swap234: '共识',
  swap235: '已解锁',
  swap236: '可领取',
  swap237: '社区治理',
  swap238: '联盟共识',
  swap239: '社区联盟',
  swap240: '质押代币',
  swap241: '质押中,请稍等',
  swap242: '交易挖矿',
  swap243: '昨日新增',
  swap244: '社区白名单累计奖励',
  swap245: '股权星卡累计奖励',
  swap246: 'LP挖矿累计奖励',
  swap247: '当前可领取',
  swap248: '当前可领取金额',
  swap249: '交易挖矿',
  swap250: '我的分享奖励累计',
  swap251: '我的交易奖励累计',
  swap252: '请输入您的交易哈希',
  swap253: '校验',
  swap254: '请输入您的哈希',
  swap255: '您的交易地址',
  swap256: '您将获得',
  swap257: '仅买入GDT及创建流动性的交易可获得奖励',
  swap258: '质押GDT-PHO代币',
  swap259: '赎回GDT-PHO代币',
  swap260: '全网手续费累计',
  swap261: '待领取分红',
  swap262: 'GDT联合股东',
  swap263: '社交',
  swap264: '商城',
  swap265: '精选好物',
  swap266: '优选小店',
  swap267: '即将上线',
  swap268: 'VGT联创股东',
  swap269: '质押VGT-PHO代币',
  swap270: '赎回VGT-PHO代币',
  swap271: '推荐链接',
  swap272: '请绑定领导人',

  swap273: '详细',
  swap274: '元宇宙',
  swap275: '全局',
  swap276: '交换和流动性',
  swap277: '交易、发展、赚钱、',
  swap278: '多链聚合DEX生态平台',
  swap279: '享受最低的交易费,有利可图的功能和多类型推荐计划',
  swap280: '路线图',
  swap281: '2022年7月 2.0版上线',
  swap282: '2022年9月 开通持币多挖',
  swap283: '2022年12月 NFT交易平台上线',
  swap284: '2023年6月 Goodbaby游戏上线',
  swap285: '2023年9月 Goodlend上线',
  swap286: '了解更多',
  swap287: '您已持有',
  swap288: '在',
  swap289: '交易加密数字货币',
  swap290: '质押流动性和代币',
  swap291: '赚取分红奖励',
  swap292: '发射台',
  swap293: '认证',
  swap294: '优质加密数字资产',
  swap295: '了解',
  swap296: '艺术品',
  swap297: '交易市场',
  swap298: '浏览',
  swap299: '即时兑换代币',
  swap300: '复制成功',
  swap301: '不支持复制,该浏览器不支持自动复制',
  swap302: '联创累计奖励',
  swap303: '累计分享奖励',
  swap304: '累计交易奖励',
  swap305: '资产包',
  swap306: '交易赚币',
  swap307: '可取出余额',
  swap308: '请输入交易哈希',
  swap309: '确定',
  swap310:'请输入hash值',
  swap311:'入账成功',

  swap312:'币安',
  swap313:'平台',
  swap314:'PHO专区',
  swap315:'GDT专区',
  swap316:'VGT专区',
swap317:'PHO星级卡',
swap318:'个人',
swap319:'团队',
swap320:'钱包中的',
swap321:'选择资产包',
swap322:'请选择',
swap323:'日收益率',
swap324:'收益比例',
swap325:'到期总数',
swap326:'购买',
swap327:'您的钱包余额',
swap328:'我的资产包',
swap329:'今日总收益',
swap330:'今日静态收益',
swap331:'今日动态收益',
swap332:'累计收益',
swap333:'到期数量',
swap334:'选择',
swap335:'加密货币专区',
swap336:'购买成功',
swap337:'进入 DApp',
swap338:'圈子',
swap339:'与你的加密圈伙伴一起畅聊',
swap340:'糖果盒',
swap341:'多种优质资产空投',
swap342:'快来免费领取',
swap343:'众创池',
swap344:'铸币中心',
swap345:'社区圈子',
swap346:'糖果盒子',
swap347:'赚币',
swap348:'空投',
swap349:'分享中心',
swap350:'联盟计划',
swap351:"社交",
swap352:"桥",
swap353:"映射",
swap354:"Water 矿池",
swap355:"参与矿池建设,自由赚取高额激励",
swap356:"流动性矿池",
swap357:"单币质押池",
swap358:"实时",
swap359:"已完成",
swap360:"仅限已质押",
swap361:"排序依据",
swap362:"搜索",
swap363:" 热门 ",
swap364:"搜索矿池",
swap365:"这里什么也没有！",
swap366:"首个Water Swap的启动模板",
swap367:"为每个优质投资者和加密资产找到最佳匹配路径 ",
swap368:"创建ISO",
swap369:"成为未来的加密明星",
swap370:"即将到来",
swap371:"请先绑定推荐人",
swap372:"我的邀请人",
swap373:"邀请人地址：",
swap374:"",
swap375:"Q3  推出并上线D6 Swap,并开启创世铸币,同时引入加密社交平台CIRCLE,以深度链接社区生态发展。",
swap376:"Q4  对D6进行流动性注入,开启 “D6共识计划”,打造D6流通共识,并启用流动性激励计划。推出链上联盟跨境商城,打造“消费增值”生态,推动社区与商业结合新方向。",
swap377:"",
swap378:"Q1 一揽子联盟扶持计划,推动D6作为基础流动性支持,提高市场认可和占比度,上线D-Pay,促进D6 Coin的实体交易流通",
swap379:"Q2 正式上线D6 Trade,开启CEX和DEX双交易平台时代,进一步提高D6市场交易流动性,并引入新的伙伴。",
swap380:"",
swap381:"",
d1:"充币",
d2:"提币",
d3:"转账",
d4:"账单",
d5:"社区",
d6:"闪兑",
d7:"问题反馈",
d8:"铸造",
d9:"赚取D6 Coin",
d10:"收益高达",
d11:"进入",
d12:"Water行情",
d13:"今日价格",
d14:"今日收益",
d15:"查看",
d16:"资产",
d17:"隐藏零资产",
d18:"币种",
d19:"冻结",
d20:"可用",
d21:"选择通证",
d22:"充值数量",
d23:"请输入充值数量",
d24:"钱包余额:",
d25:"入账地址",
d26:"确定",
d27:"充值记录",
d28:"提取数量",
d29:"请输入提取数量",
d30:"账户余额：",
d31:"到账地址",
d32:"提币记录",
d33:"转账数量",
d34:"请输入转账数量",
d35:"输入对方地址",
d36:"请输入对方地址",
d37:"转账记录",
d38:"资产兑换",
d39:"支付",
d40:"得到",
d41:"数量",
d42:"全部",
d43:"最多可兑换：",
d44:"交易税：",
d45:"应到账：",
d46:"支付数量：",
d47:"闪兑记录",
d48:"确认兑换",
d49:"闪兑记录",
d50:"已收益",
d51:"待收益",
d52:"燃烧金额",
d53:"请输入燃烧金额",
d54:"进行中",
d55:"已结束",
d56:"流通进度",
d57:"暂无记录！",
d58:"历史收益",
d59:"我的等级",
d60:"当前等级",
d61:"注册后即可成为 D0用户",
d62:"好友邀请计划",
d63:"邀请好友共同铸币,开启Water共识革命,加速赚取奖励！",
d64:"复制链接",
d65:"我的推荐人",
d66:"我的有效推荐",
d67:"地址",
d68:"级别",
d69:"业绩",
d70:"下一等级：",
d71:"市场业绩",
d72:"尚未完成",
d73:"市场部门",
d74:"您已是最高级别",
d75:"公告",
d76:"绑定确认",
d77:"领导人地址：",
d78:"确认绑定",
d79:"不能为负和零",
d80:"余额不足",
d81:"充币成功",
d82:"充币失败",
d83:"手续费：",
d84:"转入",
d85:"转出",
d86:"账单记录",
d87:"时间",
d88:"类型",
d89:"数量",
d90:"币种",
d91:"请先选择支付通证",
d92:"此通证禁止双向兑换",
d93:"不能选择相同的通证",
d94:"请先绑定领导人",
d95:"剩余可燃烧金额",
d96:"请先绑定推荐人",
d97:"请填写推荐人邀请码",
d98:"最新公告",
d99:"注：",
d100:"2:提币订单,预计为T+1日确认",
d101:"1:单笔提币 {min_withdraw} {token_name} 起",
d102:"3:每笔提币收取{rate}% {token_name}税收",
d103:"团队地址",
d104:"团队有效",
d105:"今日充值",
d106:"今日提取",
d107:"个人",
d108:"团队",
d109:"开启后,达到铸造条件,自动参与铸造",
d110:"充值后预计1-2分钟到账",
d111:"每笔转账收取{feerate}%交易税",
d112:"如有疑问,请遗留问题反馈！",
d113:"反馈问题",
d114:"反馈列表",
d115:"反馈内容",
d116:"线上客服",
d117:"暂未回复",
d118:"回复：",
d119:"1.意见反馈",
d120:"2.添加图片(最多3张)",
d121:"提交",
d122:"请上传png或jpg格式图片",
d123:"上传中,请稍候...",
d124:"文件大小不能超过 10M",
d125:"用户",
d126:"CIRCLES  AirDrop 矿池",
d127:"发行总量",
d128:"当前产出",
d129:"提取",
d130:"我的余额",
d131:"查看",
d132:"分享人数",
d133:"工作的矿机",
d134:"时",
d135:"分",
d136:"秒",
d137:"挖矿中",
d138:"启动挖矿",
d139:"邀请好友",
d140:"好友启动挖矿",
d141:"获得分享收益",
d142:"一级分享",
d143:"地址",
d144:"状态",
d145:"没有更多了",
d146:"二级分享",
d147:"创世挖矿",
d148:"进入",
d149:"CIRCLES致力于加密社交隐私公链建设,计划将于2025年推出,公链代币总量20亿枚,每个用户可领取1T算力,逐量衰减,累计免费挖出80%即止。",
d150:"总资产估值",
d151:"9·18 通证",
d152:"9·18通证是WaterSwap合作的首个以历史题材社区通证,以“人人持有、永世不忘”为目标,铭记历史,杜绝淡化,从9·18通证开始 ！即刻免费领取吧！",
d153:"挖矿规则",
d154:"每个用户可获得1T算力,每T算力初始24小时产出30枚,每产出1000万枚算力递减20%。",
d155:"直接邀请50%,间接邀请25%算力加成激励！",
d156:"领取空投",
d157:"提币尚未开放",
d158:"当您绑定市场领袖后,即可领取9.18枚918通证空投。",
d159:"若您参与分享,每分享10个用户领取空投,您将再获得一次空投奖励资格,最高可获得918枚！",
d160:"我的空投",
d161:"普惠空投",
d162:"我的分享",
d163:"提币尚未开放",
d164:"当前等级",
d165:"查看收益",
d166:"普通用户",
d167:"VIP{level} 会员",
d168:"升级",
d169:"复制邀请码",
d170:"地址",
d171:"状态",
d172:"生态",
d173:"持有卡牌",
d174:"参与生态建设,获得丰厚奖励",
d175:"今日可领取",
d176:"C2C交易市场",
d177:"当前等级",
d178:"领取",
d179:"进入",
d180:"您没有邀请码",
d181:"我的市场",
d182:"不能购买",
d183:"您的领袖未成为会员",
d184:"链上余额不足",
d185:"平台余额不足",
d186:"签名失败",
d187:"转账失败",
d188:"续费",
d189:"邀请码",
d190:"你需支付",
d191:"钱包余额",
d192:"余额",
d193:"平台余额",
d194:"支付中...请等待",
d195:"支付成功",
d196:"确定支付",
d197:"关闭",
d198:"支付失败",
d199:"交易",
d200:"赚币",
d201:"空投",
d202:"社区",
d203:"生态中心",
d204:'选择网络',
d205:"枚",
d206:"已领取",
d207:"未领取",

h1:'首页',
h2:'矿池',
h3:'交易',
h4:'发射台',
h5:'伙伴',
h6:'成为ONE DEX合伙人',
h7:'畅享八大生态权益，快来参与吧！',
h8:'进入',
h9:'币种',
h10:'可用额',
h11:'冻结额',
h12:'在ONE DEX上',
h13:'交易加密数字货币',
h14:'参与规则',
h15:'招募规则',
h16:'ONE DEX 合伙人招募,招募 200名创世合伙人,分3期开展,相关规则如下:',
h17:'招募条件：',
h18:'招募期支付',
h19:'获得规则：',
h20:'1：分享5个有效合伙人自动升级为创世合伙人，先到先得，计划200席位创世合伙人：先发行一个HAPC代币，上线前把 HAPC 代币映射为HAP',
h21:'第一期30个，空投16个/人；',
h22:'第二期60个，空投11个/人；',
h23:'第三期110个，空投6个/人。',
h24:'如果形势好，可以出公告，增加第四期N个（计划），空投n个/人',
h25:'2：合伙人推荐合伙人可享受20%的邀请奖励',
h26:'3：创世合伙人邀请合伙人可获得30%邀请激励 + 10%团队无限代邀请激励（平级除外）',
h27:'4、无合伙人邀请链接，不能操作充值成为合伙人，也不能推荐合伙人',
h28:'5、系统后端增设：创世合伙人、合伙人的开关设置。',
h29:'合伙人招募计划',
h30:'第一期',
h31:'第{round}期',
h32:'第三期',
h33:'招募进度',
h34:'开始时间：',
h35:'结束时间：',
h36:'招募席位：',
h37:'席位费用：',
h38:'账户余额',
h39:'加入',
h40:'不可加入',
h41:'最新合伙人',
h42:'地址',
h43:'支付金额',
h44:'状态',
h45:'已完成',
h46:'签名失败',
h47:'ONE DEX矿池',
h48:'在ONE DEX矿池,自由赚取高额激励',
h49:'首个ONE DEX的启动模板',
h50:'暂无信息~',
h51:'全新上线',
h52:'交易就来ONE DEX',
h53:'高效率 多收益',
h54:'跨链桥',
h55:'普通用户',
h56:'合伙人',
h57:'创世合伙人',
h58:'问题反馈',
h59:'空投活动',
h60:'NFT 中心',
h61:'生态AI',
h62:'铭文铸造',
h63:'合伙人权益',
h64:'1: 成为合伙人必须上级也是合伙人',
h65:'2: 合伙人推荐合伙人可以获得20%推荐奖励',
h66:'3: 直接分享5个合伙人,可自动升级为“创世合伙人”,最多200个席位,结束为止。',
h67:'4: 创世合伙人推荐合伙人可以获得30%推荐奖励+ 10%无限代市场奖励（平级则无）。 ',
h68:'5: 创世合伙人可以获得HAPC并在上线前映射为HAP,规则如下: ',
h69:'第一期30个席位,空投15 / 席；',
h70:'第二期60个席位,空投10 / 席；',
h71:'第三期110个席位,空投5 / 席。',
h72:'6: 合伙人每日可获得10枚HAPB空投权益。',
h73:'温馨提示:',
h74:'1: 合伙人席位有限,仅招募期间可参与。',
h75:'2: 抢购席位前,需确保您的账户存在足够的余额,需转入账户资产。',
h76:'招募介绍',
h77:'Happyswap是一家去中心化现货合约交易所,为用户提供安全、高效、的交易体验。消除价格影响,打破原有的平台垄断和操控。用户可以在HappySwap平台进行多种数字资产交易和其他数字资产衍生品交易。HappySwap合伙人全球限量发行,作为合伙人不光可以享受八大合伙人权益,整个平台的盈利跟所有合伙人都有着紧密的关系。数字资产新时代,共同打造真正去中心化交易新玩法,共享数字金融万倍神话。',
h78:'剩余席位',
h79:'合伙人',
h80:'创世',
h81:'普通',
h82:'合伙',
h83:'本期未开启',
h84:'加入',
h85:'本期已招满',
h86:'本期已结束',
h87:'充提',
h88:'转账',
h89:'划转',
h90:'了解',
h91:'娱乐游戏',
h92:'AI超算',
h93:'常见问题',
h94:'加入',
h95:'获取管道新机遇,点击进入吧！',
h96:'经验丰富',
h97:'我们的核心成员来自UNISWAP、MDEX等，具有丰富的去中心化平台经验！',
h98:'联盟计划',
h99:'加入俱乐部，提供基于同一市场网体，多种不同机制下收益的可能，成为您稳定可靠的财富伙伴!',
h100:'安全保障',
h101:'ONE DEX所有合约均开源并进行完善审计，对未开源及审查的通证将拒绝合作！',
h102:'DAO共建',
h103:'ONE DEX 以49DAO为领导，秉承“一个中心， N个联盟”的社群经济理念，以社区治理社区，充分实现DAO治理化。',
h104:'路线图',
h105:'2023年12月',
h106:'49DAO顶层发起伙伴筹备',
h107:'2024年02月',
h108:'49DAO对接初创社区',
h109:'2024年03月',
h110:'49DAO筛选邀请名单',
h111:'2024年05月',
h112:'ONE DEX平台上线',
h113:'并开启Super ONE矿池',
h114:'2024年09月',
h115:'筹建并推动DEX生态伙',
h116:'伴计划，发布DEX全新',
h117:'发展白皮书',
h118:'2025年度',
h119:'推动平台及优质伙伴数字',
h120:'Token上线多家知名CEX平台',
h121:'申请上架',
h122:'用户',
h123:'在ONE DEX上',
h124:'优势',
h125:'过期',


o101:'待产出算力',
o102:'今日算力收益',
o103:'今日分享收益',
o104:'账单',
o105:'查看',
o106:'个人有效能量',
o107:'最大社区能量',
o108:'其它社区数',
o109:'龙虎榜',
o110:'能量值：',
o111:'算力值：',
o112:'兑换',
o113:'未开放',
o114:'已产出：',
o115:' 待产出：',
o116:'产出进度',
o117:'你需支付',
o118:'账户余额',
o119:'余额',
o120:'确定支付',
o121:'NFT商店',
o122:'产出中',
o123:'已耗尽',
o124:'邀请好友',
o125:'购买NFT',
o126:'获得分享收益',
o127:'地址',
o128:'个人能量',
o129:'团队能量',
o130:'算力收益',
o131:'已领取',
o132:'待领取',
o133:'领取',
o134:'分享收益',
o135:'龙虎榜',
o136:'时间',
o137:'数量',
o138:'币种',
o139:'算力账单',
o140:'分享账单',
o141:'龙虎榜账单',
o142:'兑换时间',
o143:'产出中',
o144:'已耗尽',
o145:'NFT中心',
'待产出总值':'待产出总值',
'今日己产出':'今日己产出',
'累计已产出':'累计已产出',
'去交易':'去交易',
'账户可用资产':'账户可用资产',
'确定':'确定',
'今日产出':'今日产出',
'团队总张数':'团队总张数',
'最大社区数':'最大社区数',
'原始值':'原始值',
'产出值':'产出值',
'激活':'激活',
'已激活':'已激活',
'未激活':'未激活',
'到期时间':'到期时间',
'你需要销毁':'你需要销毁',
'确定销毁':'确定销毁',
'星石矿卡':'星石矿卡',
'工作中':'工作中',
'已完成':'已完成',
'级别指南':'级别指南',
'升级':'升级',
'当前张数':'当前张数',
'距离':'距离',
'需':'需',
'个人张数':'个人张数',
'团队张数':'团队总数',
'普通':'普通',
'R级':'R级',
'S级':'S级',
'SR级':'SR级',
'SSR级':'SSR级',
'UR级':'UR级',
'最大社区':'最大社区',
'其它社区':'其它社区',
'矿卡收益':'矿卡收益',
'状态':'状态',
'领取':'领取',
'已到账':'已到账',
'已提取':'已提取',
'矿卡产出':'矿卡产出',
'布道津贴':'布道津贴',
'社区津贴':'社区津贴',
'孵化津贴':'孵化津贴',
'提取记录':'提取记录',
'星级指南':'星级指南',
'星级介绍':'星级介绍',
'根据团队当前持有有效的星石矿卡评定星级用户，分为5个等级':'根据团队当前持有有效的星石矿卡评定星级用户，分为5个等级',
'星级权益':'星级权益',
'可同时持有19张矿卡,并获得小业绩区新增业绩1%的加速！':'可同时持有19张矿卡,并获得小业绩区新增业绩1%的加速！',
'可同时持有29张矿卡,并获得小业绩区新增业绩3%的加速！':'可同时持有29张矿卡,并获得小业绩区新增业绩3%的加速！',
'可同时持有39张矿卡,并获得小业绩区新增业绩5%的加速!在出现平级时可获得50%平级收入孵化津贴！':'可同时持有39张矿卡,并获得小业绩区新增业绩5%的加速!在出现平级时可获得50%平级收入孵化津贴！',
'可同时持有49张矿卡,并获得小业绩区新增业绩7%的加速!在出现平级时可获得40%平级收入孵化津贴！':'可同时持有49张矿卡,并获得小业绩区新增业绩7%的加速!在出现平级时可获得40%平级收入孵化津贴！',
'可同时持有49张矿卡,并获得小业绩区新增业绩9%的加速!在出现平级时可获得30%平级收入孵化津贴！':'可同时持有49张矿卡,并获得小业绩区新增业绩9%的加速!在出现平级时可获得30%平级收入孵化津贴！',
'当团队持有矿卡到期后，有效张数失效，级别存在下降可能！':' 当团队持有矿卡到期后，有效张数失效，级别存在下降可能！',
'买单':'买单',
'卖单':'卖单',
'我的订单':'我的订单',
'请输入最小价格':'请输入最小价格',
'请输入最大价格':'请输入最大价格',
'最小价格：':'最小价格：',
'最大价格：':'最大价格：',
'取消':'取消',
'清除价格':'清除价格',
'选择发布时间':'选择发布时间',
'清除时间':'清除时间',
'发布时间：':'发布时间：',
'数量：':'数量：',
'单价':'单价',
'支付方式：':'支付方式：',
'卖出':'卖出',
'买入':'买入',
'最新成交价':'最新成交价',
'发布买单':'发布买单',
'发布卖单':'发布卖单',
'选择年月日时':'选择年月日时',
'购买数量':'购买数量',
'卖出数量':'卖出数量',
'购买单价':'购买单价',
'卖出单价':'卖出单价',
'请输入购买数量':'请输入购买数量',
'请输入卖出数量':'请输入卖出数量',
'请输入购买单价':'请输入购买单价',
'请输入卖出单价':'请输入卖出单价',
'购买总价':'购买总价',
'卖出总价':'卖出总价',
'账户余额':'账户余额',
'说明：交易星石收取税收手续费，买入星石收取':'说明：交易星石收取税收手续费，买入星石收取',
'税收，卖出星石收取':'税收，卖出星石收取',
'税收':'税收',
'全部':'全部',
'当前剩余可交易数量：':'当前剩余可交易数量：',
'个人账户数量余额：':'个人账户数量余额：',
'个人账户数量余额：':'个人账户数量余额：',
'可得：':'可得：',
'需支付':'需支付',
'购买价格':'购买价格',
'发布时间':'发布时间',
'自动计算':'自动计算',
'请选择发布时间区间':'请选择发布时间区间',
'请选择正确的发布时间区间':'请选择正确的发布时间区间',
'请输入最小最大价格':'请输入最小最大价格',
'请输入正确价格':'请输入正确价格',
'请输入数量和价格':'请输入数量和价格',
'取消订单':'取消订单',
'已完成':'已完成',
'已取消':'已取消',
'订单号':'订单号',
'星石单价':'星石单价',
'星石总量':'星石总量',
'星石总价':'星石总价',
'已完成数量':'已完成数量',
'可撤销数量':'可撤销数量',
'发布时间':'发布时间',
'暂无订单':'暂无订单',
'发布中':'发布中',
'已完成':'已完成',
'已取消':'已取消',
'星石矿池':'REAL  A',
'铸造星石':'铸造星石',
'探索无限':'探索无限',
'首发RWA星石矿池NFT！':'首发RWA星石矿池NFT！',
'专属NFT':'专属NFT',
'持有张数':'持有张数',
'张':'张',
'所属系列':'所属系列',
'挂卖':'挂卖',
'转赠':'转赠',
'NFT系列':'NFT系列',
'加入我们的社区':'加入我们的社区',
'核心价值观影响着社区的文化，并决定前进的道路。如何听取更多的声音，及时有效的向所有社区成员传达讯息，显得极为重要，在未来进行治理模型时，既要着眼于未来，亦要注重当前的需求，兼顾个人和集体，不断提高，我们乐于与您共同参与到未来的建设！':'核心价值观影响着社区的文化，并决定前进的道路。如何听取更多的声音，及时有效的向所有社区成员传达讯息，显得极为重要，在未来进行治理模型时，既要着眼于未来，亦要注重当前的需求，兼顾个人和集体，不断提高，我们乐于与您共同参与到未来的建设！',
'加入社区':'加入社区',
'已加入社区':'已加入社区',
'市场':'市场',
'转赠':'转赠',
'矿池':'矿池',
'记录':'记录',
'暂未开放':'暂未开放',
'其他说明':'其他说明',
'可交易':'可交易',
'交易中':'交易中',
'类型':'类型',
'已划转':'已划转',
'划转':'划转',
'待划转':'待划转',
'划转记录':'划转记录',
'待产出星石':'待产出星石',
'我的邀请码':'我的邀请码',
'复制邀请码':'复制邀请码',
'我的邀请人':'我的邀请人',
'填写邀请码':'填写邀请码',
'请输入邀请码':'请输入邀请码',
'确认':'确认',
'矿池':'矿池',
'集市':'集市',
'社区':'社区',
'请先绑定推荐人':'请先绑定推荐人',
'请填写推荐人地址':'请填写推荐人地址',
'今日总产出':'今日总产出',
'账户可用星石':'账户可用星石',
'我的产出':'我的产出',
'累计已领':'累计已领',
'今日待领':'今日待领',
'权益中心':'权益中心',
'提案公投':'提案公投',
'席位招募':'席位招募',
'治理中心':'治理中心',
'董事竞选':'董事竞选',
'竞选成为管理委员会':'竞选成为管理委员会',
'董事':'董事',
'发起提案':'发起提案',
'发起社区发展提案':'发起社区发展提案',
'投票决议':'投票决议',
'参与提案投票表决':'参与提案投票表决',
'获得激励':'获得激励',
'获得税收分红激励':'获得税收分红激励',
'节点激励':'节点激励',
'已领取':'已领取',
'待领取':'待领取',
'节点账单':'节点账单',
'时间':'时间',
'数量':'数量',
'来源':'来源',
'暂无数据':'暂无数据',
'领取成功':'领取成功',
'提案公投':'提案公投',
'发起治理提案，并获得不少于10位董事支持后':'发起治理提案，并获得不少于10位董事支持后',
'则将进行下一步公投':'则将进行下一步公投',
'提交提案':'提交提案',
'进行中':'进行中',
'即将开始':'即将开始',
'已结束':'已结束',
'搜索':'搜索',
'去投票':'去投票',
'实际转出':'实际转出',
'张':'张',
'选择张数':'选择张数',
'您最多可兑换':'您最多可兑换',
'您需支付':'您需支付',
'超出限制':'超出限制',

'欢迎进入':'欢迎进入',
'49DAO娱乐链游生态平台':'49DAO娱乐链游生态平台',
'49链游是49DAO联合多个厂商':'49链游是49DAO联合多个厂商共同打造的联盟链游孵化平台，旨在为游戏服务商提供链化改革，为用户构建娱乐收益场景，致力于打造WEB3.0 + AI + Gamefi +SocialFi 的新式体验。',
'参与游戏建设':'参与游戏建设',
'即将到来':'即将到来',
'火柴人战争遗产':'火柴人战争遗产',
'火柴人战争遗产简介':'一款策略塔防+角色扮演类手游，玩家需要扮演一位君主，平息火柴人世界的纷争，收复各种各样的兵种，最终统一火柴人世界。游戏有着多样化的兵种配置和丰富的角色设定，赶紧来看看吧！',
'平凡大冒险':'平凡大冒险',
'平凡大冒险简介':'《平凡大冒险》是一款感人的探索类经营游戏，讲述了一个年轻小伙子的真实生活冒险故事。这名年轻人为了追寻自己的梦想，勇敢地离开了家乡前往永兴县，开始了一段充满挑战的生存旅程。 《平凡大冒险》传达了奋斗和乐观的精神，让你在游戏中探索生活的方方面面，从工作到休息，从娱乐到人际关系，全面体验一个年轻人的成长与奋斗。希望你能在这个游戏中找到乐趣，并体会到生活的丰富多彩。',
'刺客猎人':'刺客猎人',
'刺客猎人简介':'刺客猎人是一款十分有趣的冒险闯关类游戏，在游戏中，你是一个职业的杀手，但是你却没有强力的武器，只能通过暗杀来完成任务，在敌人背后悄无声息的执行暗杀，不要引起骚动，否则你将死无葬身之地！',
'地下城割草':'地下城割草',
'地下城割草简介':'全新升级的地下城割草！新增人物与地图！面对大波敌人的来袭，你可以利用百种道具对抗千种小怪，无限搭配，打造最强的英雄，爽快的打击感，极尽的割草体验，选择最强的主动道具，来完成自己的滚雪球式超快发育，轻松干掉最终boss，你就是地下城唯一的王！（此版本无复活机会）',
'更多游戏':'更多游戏',
'49DAO将推动多个游戏厂商进行联盟合作':'49DAO将推动多个游戏厂商进行联盟合作，共同推出多款链上游戏，并打造一站式游戏平台，为用户提供多样化，趣味化、收益性 并存的生态平台，实现链游、手游、桌游等多种游戏体验。',
'合作伙伴':'合作伙伴',
'去购买':'去购买',
'生态系统':'生态系统',
'矿池':'矿池',
'NFT中心':'NFT中心',
'您有星石NFT可激活':'您有星石NFT可激活',
'确定':'确定',

'成员招募':'成员招募',
'招募进行中':'招募进行中',
'GGslayer是Web3':'GGslayer是Web3领先的游戏发行平台，完善的技术兼容性，让平台可以为开发者和用户提供深度的服务和舒畅体验，同时区块链技术的应用可以更好的平衡用户、游戏、和发行平台之间的价值分配关系。',
'邀请好友':'邀请好友',
'好友参与协议':'好友参与协议',
'获得分享激励':'获得分享激励',
'我的分享':'我的分享',
'地址':'地址',
'状态':'状态',
'普通用户':'普通用户',
'有效用户':'有效用户',
'暂无信息':'暂无信息',
'我的级别':'我的级别',
'我的余额':'我的余额',
'抽奖专区':'抽奖专区',
'抽奖':'抽奖',
'我的奖品':'我的奖品',
'未中奖':'未中奖',
'暂无奖品':'暂无奖品',
'查看':'查看',
'我的直推':'我的直推',
'我的战队':'我的战队',
'社区等级说明':'社区等级说明',
'等级':'等级',
'条件':'条件',
'权益':'权益',
'升级':'升级',
'选择支付':'选择支付',
'账户余额':'账户余额',
'钱包余额':'钱包余额',
'确定':'确定',
'支付成功':'支付成功',
'普通用户':'普通用户',
'ONEDEX注册用户':'ONEDEX注册用户',
'无':'无',
'有效用户':'有效用户',
'绑定领袖并完成1U验证':'绑定领袖并完成1U验证',
'盲盒抽奖机会':'盲盒抽奖机会',
'初级社区':'初级社区',
'分享有效5，战队有效50':'有效5，  战队50',
'15U可升级':'15U可升级',
'中级社区':'中级社区',
'分享有效20，战队有效200':'有效20，战队200',
'30U可升级':'30U可升级',
'高级社区':'高级社区',
'分享有效40，战队有效400':'有效40，战队400',
'60U可升级':'60U可升级',
'不可购买':'不可购买',
'未绑定上级':'未绑定上级',
'已购买':'已购买',
'入账中':'入账中',
'余额不足':'余额不足',
'支付失败':'支付失败',
'签名失败':'签名失败',
'升级成功':'升级成功',
'升级失败':'升级失败',
'社区权益中心':'社区权益中心',
'恭喜您成为':'恭喜您成为',
'社区节点':'社区节点',
'ONE手续费激励':'ONE手续费激励',
'星石手续费激励':'星石手续费激励',
'GGS手续费激励':'GGS手续费激励',
'ONE账单':'ONE账单',
'星石账单':'星石账单',
'GGS账单':'GGS账单',
'等待开放':'等待开放',
'恭喜获得':'恭喜获得',
'请填写推荐码':'请填写推荐码',
'绑定':'绑定',
'服务节点':'服务节点',
'超级节点':'超级节点',
'币种':'币种',
'税收激励池':'税收激励池',
'已赚取':'已赚取',
'领取':'领取',
'质押':'质押',
'授权':'授权',
'单币激励池':'单币激励池',
'激励池':'激励池',
'赎回':'赎回',

'铸币账单':'铸币账单',
'流通账单':'流通账单',
'流通激励':'流通激励',
'已领取':'已领取',
'待领取':'待领取',
'领取':'领取',
'销毁':'销毁',
'获得':'获得',
'类型':'类型',
'矿池余额':'矿池余额',
'今日流通':'今日流通',
'累计流通':'累计流通',
'账单':'账单',
'直接分享':'直接分享',
'团队分享':'团队分享',
'销毁挖矿':'销毁挖矿',
'请输入您的交易哈希':'请输入您的交易哈希',
'请校验哈希':'请校验哈希',
'校验':'校验',
'交易地址':'交易地址',
'您本次销毁':'您本次销毁',
'您将获得':'您将获得',
'确定':'确定',
'请输入交易哈希':'请输入交易哈希',
'获得加速流通':'获得加速流通',
'最大分享区':'最大分享区',
'其他分享区':'其他分享区',
'团队有效':'团队有效',
'成员':'成员',
'用户':'用户',

'销毁即挖矿':'销毁即挖矿',
'持续销毁 感受增长':'持续销毁 感受增长',
'暂停交易':'暂停交易',
'不可交易':'不可交易',
'Hamster激励':'Hamster激励',
'已置换':'已置换',
'质押中':'质押中',
'置换池':'置换池',
'质押池':'质押池',
'Hamster账单':'Hamster账单',
'矿池待产余额大于或等于26250时为有效用户可享受分享奖励；当低于时您需补足，否则不再享有分享奖励。':'矿池待产余额大于或等于26250时为有效用户可享受分享奖励；当低于时您需补足，否则不再享有分享奖励。',
'停止新增':'停止新增',
'兑换':'兑换',
'即时闪兑代币':'即时闪兑代币',
'发送':'发送',
'余额':'余额',
'币安智能链':'币安智能链',
'接收（预估）':'接收（预估）',
'兑换价格':'兑换价格',
'最少接收数量':'最少接收数量',
'兑换路径':'兑换路径',
'农场/流动性':'农场/流动性',
'生态':'生态',
'龙虎榜':'龙虎榜',
"当前奖池金额":"当前奖池金额",
"幸运榜单":"幸运榜单",
"开奖倒计时":"开奖倒计时",
"分享榜":"分享榜",
"质押激励":"质押激励",
"社区激励":"社区激励",
"分享激励":"分享激励",
"榜单奖励":"榜单奖励",
"全网社区算力":"全网社区算力",
"全网质押算力":"全网质押算力",
"个人算力":"个人算力",
"其他社区":"其他社区",
"挖矿中":"挖矿中",
"已赎回":"已赎回",
"质押数量":"质押数量",
"单次新增数量为1的倍数":"单次新增数量为1的倍数",
"铸币池":"铸币池",
"矿卡":"矿卡",
"算力奖励":"算力奖励",
"社区奖励":"社区奖励",
"分享奖励":"分享奖励",
"赎回后不再享受发行激励":"赎回后不再享受发行激励",
"当前质押未满天需收取管理费":"当前质押未满{day}天需收取{rate}%管理费",
"质押类型":"质押类型",
"质押时间":"质押时间",
"质押数量":"质押数量",
"赎回时间":"赎回时间",
"赎回":"赎回",
"确定":"确定",
"取消":"取消",
"未开启":"未开启",
"不可赎回":"不可赎回",
"今日收益":"今日收益",
"请输入质押数量":"请输入质押数量",
"不可质押":"不可质押",
"团队算力":"团队算力"
};
