<template>
    <div class="EarnBox">
        <div class="EarnBox_line" style="margin-bottom: 22px;" @click="pathNamePushUrl({pathName:'farm',isUrlLink:false})">
            <div class="EarnBox_line_left">
                <img src="@/assets/imgsnowball/farm_icon.png" class="EarnBox_line_img" alt="">
                <div>{{$t('lang.农场/流动性')}}</div>
            </div>

        </div>
        <div class="EarnBox_line">
            <div class="EarnBox_line_left">
                <img src="@/assets/imgsnowball/ecology_icon.png" class="EarnBox_line_img" alt="">
                <div>{{$t('lang.生态')}}</div>
            </div>
            <van-icon name="arrow-down" />
        </div>
        <div>
            <div class="gird-inner">
                <div class="EarnBox_line" style="margin-top: 21px;" @click="pathNamePushUrl(item)" v-for="(item,index) in ecologyList" :key="index">
                    <div class="EarnBox_line_left">
                        <div class="EarnBox_line_img"></div>
                        <div
                            style="width: 5px; height: 5px; border-radius: 50%; background: #CCCCCC; margin-right: 9px;">
                        </div>
                        <div>{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShowContext: true,
            ecologyList:[
            {
                name:'HAMSTER POOL',
                image:'',
                pathName:'HamsterIndex',
                isUrlLink:false,
            },
            {
                name:'REAL A',
                image:'',
                pathName:'MintPool',
                isUrlLink:false,
            },
            {
                name:'SUPER ONE',
                image:'',
                pathName:'https://supper.onedex.club/pages/login/index',
                isUrlLink:true,
            }
        ]
        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
         pathNamePushUrl(val){
            if(val.isUrlLink){
                window.location.href = val.pathName
            }else{
                this.$router.push({
                    name:val.pathName
                })
            }
         }
    },
}

</script>
<style scoped lang='less'>
.EarnBox {
    width: 321px;
    opacity: 1;
    border-radius: 20px;
    background: #FFFFFF;
    padding: 21px 16px;
}

.gird {
    display: grid;
    overflow: hidden;
    grid-template-rows: 0fr;
    transition: grid-template-rows .5s ease-in-out;
}

.gird-open {
    grid-template-rows: 1fr !important;
}

.gird-inner {
    overflow: hidden;
}

.EarnBox_line {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    color: #929292;

    .EarnBox_line_img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .EarnBox_line_left {
        display: flex;
        align-items: center;
    }
}
</style>