import request from '../utils/request';
export default {
    lpuserteaminfo(address) {
        return request.request({
            url: '/lp/user/team-info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpuserranklist(address) {
        return request.request({
            url: '/lp/user/rank-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpusersharelist(address) {
        return request.request({
            url: '/lp/user/share-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpuserluckylist(address) {
        return request.request({
            url: '/lp/user/lucky-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpusertodayreward(address) {
        return request.request({
            url: '/lp/user/today-reward',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpusersummary(address) {
        return request.request({
            url: '/lp/user/summary',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpwalletinfo(address) {
        return request.request({
            url: '/lp/wallet/info',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    lpwalletrecordlist(params,address) {
        return request.request({
            url: '/lp/wallet/record-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params
        })
    },
    lpwalletclaim(data,address) {
        return request.request({
            url: '/lp/wallet/claim',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data
        })
    },
    lplist(address) {
        return request.request({
            url: '/lp/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    
    lpconfig(address) {
        return request.request({
            url: '/lp/config',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    
    lpholdlist(params,address) {
        return request.request({
            url: '/lp/hold/list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            params
        })
    },
    lpholdredeem(data,address) {
        return request.request({
            url: '/lp/hold/redeem',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data
        })
    },
    lpholdentrymark(data,address) {
        return request.request({
            url: '/lp/hold/entry-mark',
            method: "post",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
            data
        })
    },
    lpuserchildrenlist(address) {
        return request.request({
            url: '/lp/user/children-list',
            method: "get",
            headers:{
                Authorization : address,
                'Content-Type':'application/json',
                'lang':localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US',
            },
        })
    },
    
}