<template>
    <div class="DTlist">
        <div class="DTlist_line1">
            <img src="@/assets/img/DTlist_icon1.png" alt="">
            <span> {{ $t('lang.龙虎榜') }}</span>
        </div>
        <div class="DTlist_line4">
            {{ $t('lang.当前奖池金额') }} ：{{ rankInfo.reward_amount }}
        </div>
        <div class="DTlist_line2">
            <div class="DTlist_line2_box level2">
                <div class="DTlist_line2_box_box" v-if="rankInfo.firstThreeItems[1]">
                    <span>{{ rankInfo.firstThreeItems[1].id != 0 ? rankInfo.firstThreeItems[1].address :'?'  }}</span>
                    <span style="color: #000;">{{ rankInfo.firstThreeItems[1].id != 0 ? rankInfo.firstThreeItems[1].lp_share_cp :''  }}</span>
                </div>
            </div>
            <div class="DTlist_line2_box level1">
                <div class="DTlist_line2_box_box" v-if="rankInfo.firstThreeItems[0]">
                    <span>{{ rankInfo.firstThreeItems[0].id != 0 ? rankInfo.firstThreeItems[0].address :'?'  }}</span>
                    <span style="color: #000;">{{ rankInfo.firstThreeItems[0].id != 0 ? rankInfo.firstThreeItems[0].lp_share_cp :''  }}</span>
                </div>
            </div>
            <div class="DTlist_line2_box level3">
                <div class="DTlist_line2_box_box" v-if="rankInfo.firstThreeItems[2]">
                    <span>{{ rankInfo.firstThreeItems[2].id != 0 ? rankInfo.firstThreeItems[2].address :'?'  }}</span>
                    <span style="color: #000;">{{ rankInfo.firstThreeItems[2].id != 0 ? rankInfo.firstThreeItems[2].lp_share_cp :''  }}</span>
                </div>
            </div>
        </div>
        <div class="DTlist_line3">
            <div class="DTlist_line3_box" v-for="(item, index) in rankInfo.remainItems">
                <div class="DTlist_line3_box_left">
                    <span>{{ index + 4 }}</span>
                    <span>{{ item.address }}</span>
                </div>
                <div class="DTlist_line3_box_right">
                    <span>{{ item.lp_share_cp }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rankInfo: {
            type: Object,
            required: true,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less'>
.DTlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 22px;

    .DTlist_line4 {
        margin: 10px;
        font-size: 13px;
        color: #4185F4;
        background: url('~@/assets/img/DTlist_bg1.png') no-repeat;
        background-size: contain;
        padding: 3px 21px;
    }

    .DTlist_line3 {
        max-height: 212px;
        overflow-y: scroll;
        width: 100%;

        .DTlist_line3_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 16px;
            background: #F7F7F7;
            border-radius: 13px;
            margin-top: 12px;
            color: rgba(0, 6, 31, 0.4);
            font-size: 16px;
            font-weight: bold;
            padding-right: 48px;

            .DTlist_line3_box_left {
                display: flex;
                align-items: center;
                gap: 18px;
            }
        }
    }

    .DTlist_line2 {
        margin-top: 15px;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .level2 {
            color: #687CE3;
            background: url('~@/assets/img/DTlist_img2.png') no-repeat;
            transform: translateY(40px);
        }

        .level1 {
            color: #66B4AE;
            background: url('~@/assets/img/DTlist_img1.png') no-repeat;
        }

        .level3 {
            color: #F3BA2F;
            background: url('~@/assets/img/DTlist_img3.png') no-repeat;
            transform: translateY(40px);
        }

        .DTlist_line2_box {
            font-size: 16px;
            font-weight: bold;
            background-size: contain;
            width: 32%;
            height: 125.43px;
            display: flex;
            align-items: center;
            justify-content: center;

            .DTlist_line2_box_box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .DTlist_line1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-size: 20px;
        gap: 7px;
        font-weight: bold;

        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>