<template>
  <div class="engbill">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
    <div class="title">
      {{ $t('lang.矿卡收益') }}
    </div>
    <div class="receivebox" v-loading="loading">
      <img src="@/assets/imgsnowball/receivebox1.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.已划转') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ walletinfo.claimed }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.待划转') }}</div>
        <div class="num_num">{{ walletinfo.usable }}</div>
      </div>
      <div class="button" @click="rerveFun(0)">
        {{ $t('lang.划转') }}
      </div>
    </div>
    <div class="title">
      {{ $t('lang.我的产出') }}
    </div>
    <div class="receivebox" v-loading="loading"
      style="background: linear-gradient(138.26deg, rgba(163, 170, 222, 1) 0%, rgba(93, 104, 179, 1) 100%);">
      <img src="@/assets/imgsnowball/receivebox4.png" alt="">
      <div class="num">
        <div class="num_title">{{ $t('lang.累计已领') }}</div>
        <div class="num_num" style="opacity: 0.6;">{{ srinfo.claimed }}</div>
      </div>
      <div class="num">
        <div class="num_title">{{ $t('lang.今日待领') }}</div>
        <div class="num_num">{{ srinfo.today_claim }}</div>
      </div>
      <div class="button" @click="nftsrallclaim">
        {{ $t('lang.领取') }}
      </div>
    </div>
    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="li first">
        <div>{{ $t('lang.o136') }}</div>
        <div>{{ $t('lang.o137') }}</div>
        <div>{{ $t('lang.状态') }}</div>
      </div>
      <van-list v-model="recordlist.loading" :finished="recordlist.finished" :finished-text="`${$t('lang.d145')}`"
        @load="onLoad">
        <div class="li" v-for="(item, index) in recordlist.list" :key="index">
          <div style="color: rgba(153, 153, 153, 1);">{{ item.created_at }}</div>
          <div style="color: rgba(73, 140, 147, 1);">
            <span v-if="regionIndex == 0">+{{ item.reward }}</span>
            <span v-else>{{ item.usable_change < 0 ? item.usable_change : '+' + item.usable_change }}</span>
          </div>
          <div style="color: rgba(153, 153, 153, 1);" v-if="regionIndex == 0">
            <div v-if="item.status == 0">
              <van-tag round type="primary" color="rgba(102, 180, 174, 1)" style="padding: 3px 10px;"
                @click="nftstaticrewardclaim(item.id)">{{ $t('lang.领取') }}</van-tag>
            </div>
            <div v-else-if="item.status == 1" style="color: rgba(73, 140, 147, 1);">
              {{ $t('lang.已到账') }}
            </div>
            <div v-else-if="item.status == 2" style="color: rgba(121, 72, 234, 1);">
              {{ $t('lang.d207') }}
            </div>
          </div>
          <div style="color: rgba(153, 153, 153, 1);" v-else>{{ item.type_txt }}</div>
        </div>
      </van-list>

    </div>
  </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
  data() {
    return {
      functionOption: [{ name: 'lang.矿卡产出' }, { name: 'lang.布道津贴' }, { name: 'lang.社区津贴' }, { name: 'lang.孵化津贴' }, { name: 'lang.划转记录' }],
      regionIndex: '0',
      walletinfo: {},
      srinfo: {},
      loading: true,
      recordlist: {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          type: '0'
        }

      }
    }
  },
  components: {

  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {

      this.init()
    },
    lang() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    switchovers(index) {
      this.regionIndex = index;

      this.recordlist = {
        loading: false,
        finished: false,
        list: [],
        info: {
          page: 1,
          page_size: 10,
          type: '0'
        }

      }
      this.recordlist.info.type = String(index);
      this.isoneList()

    },
    toprouter() {
      this.$router.go(-1);

    },

    isoneList() {
      if (this.account) {
        if (this.regionIndex == '0') {
          this.nftreleaserecord()
        } else {

          this.nftwalletrecord()
        }
      }

    },
    init() {
      if (this.account) {
        this.loading = true
        this.regionIndex = '0'
        this.recordlist = {
          loading: false,
          finished: false,
          list: [],
          info: {
            page: 1,
            page_size: 10,
            type: '0'
          }

        }
        this.nftwalletinfo()
        this.isoneList()
        this.nftsrinfo()
      }

    },
    onLoad() {
      this.isoneList()
    },
    isstatusFun(index) {
      switch (index) {
        case 0:
          return '待领取'
        case 1:
          return '已领取'
        case 2:
          return '销毁'
        default:
          break;
      }
    },
    async nftsrallclaim() {
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Static Reward All Claim'), this.account);
      } catch (error) {
        return;
      }
      let data = {
        signature: sign
      }
      nft.nftsrallclaim(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.$toast(res.msg)
      })
    },
    nftsrinfo() {
      nft.nftsrinfo(this.account).then(res => {
        if (res.code == 200) {
          this.srinfo = res.data
        }

      })
    },
    async rerveFun(index) {
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Claim'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        type: index,
        signature: sign
      }
      nft.nftholdclaim(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.$toast(res.msg)
      })
    },
    async nftstaticrewardclaim(index) {

      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Static Reward Claim'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        record_id: index,
        signature: sign
      }
      nft.nftstaticrewardclaim(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.$toast(res.msg)
      })
    },
    nftreleaserecord() {
      this.recordlist.loading = true
      nft.nftreleaserecord(this.recordlist.info, this.account).then(res => {
       
        if (res.data.list != null || res.data.list.length > 0) {
          if (this.recordlist.info.page == 1) {
            this.recordlist.list = res.data.list
          } else {
            this.recordlist.list = this.recordlist.list.concat(res.data.list)
          }
          if (res.data.list.length < this.recordlist.info.page_size) {
            this.recordlist.finished = true
          } else {
            this.recordlist.info.page++
          }
          this.recordlist.loading = false
        }
      })
    },
    nftwalletrecord() {
      this.recordlist.loading = true
      nft.nftwalletrecord(this.recordlist.info, this.account).then(res => {
        if (res.data.list != null || res.data.list.length > 0) {
          if (this.recordlist.info.page == 1) {
            this.recordlist.list = res.data.list
          } else {
            this.recordlist.list = this.recordlist.list.concat(res.data.list)
          }
          if (res.data.list.length < this.recordlist.info.page_size) {
            this.recordlist.finished = true
          } else {
            this.recordlist.info.page++
          }
          this.recordlist.loading = false
        }

      })
    },
    nftwalletinfo() {
      nft.nftwalletinfo(this.account).then(res => {
        if (res.code == 200) {
          this.walletinfo = res.data
          this.loading = false
        }
      })
    },

  },
}

</script>
<style scoped lang='less'>
.functional_region {

  .region {
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: rgba(96, 96, 96, 1);
    font-size: 13px;

    .special {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
    }
  }
}

.engbill {
  padding: 20px;
  padding-top: 52px;
  padding-bottom: 44px;

}

.receivebox {
  margin-bottom: 28px;
  flex-wrap: wrap;
  padding: 20px 15px 20px 8px;
  width: 100%;
  height: 95px;
  opacity: 1;
  border-radius: 8px;
  background: linear-gradient(138.26deg, rgba(138, 212, 205, 1) 0%, rgba(102, 180, 174, 1) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 56px;
    height: 56px;
  }

  .button {
    align-self: end;
    padding: 2px 12px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 61, 184, 1);
  }

  .num {
    .num_title {
      font-size: 12px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6);
    }

    .num_num {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 12px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-top: 29px;
  padding: 15px 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 16px;
  background: rgba(247, 247, 247, 1);


  .first {
    color: rgba(153, 153, 153, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .li {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;

    &>div {
      width: calc(100% / 3);
      text-align: center;
    }

    &>div:first-child {
      text-align: left;
    }

    &>div:last-child {
      text-align: right;
    }
  }
}
</style>