<template>
    <div class="sharelist">
        <div class="sharelist_line1">
            <img src="@/assets/img/sharelist_icon1.png" alt="">
            <span>{{ $t('lang.分享榜') }}</span>
        </div>
        <div class="sharelist_line4">
            {{ $t('lang.当前奖池金额') }}：{{ shareInfo.reward_amount }}
        </div>
        <div class="sharelist_line2">
            <div class="sharelist_line2_box level2">
                <div class="sharelist_line2_box_box" v-if="shareInfo.firstThreeItems[1]">
                    <span>{{ shareInfo.firstThreeItems[1].id != 0 ? shareInfo.firstThreeItems[1].address :'?'  }}</span>
                    <span style="color: #000;">{{ shareInfo.firstThreeItems[1].id != 0 ? shareInfo.firstThreeItems[1].addition_cp :''  }}</span>
                </div>
            </div>
            <div class="sharelist_line2_box level1">
                <div class="sharelist_line2_box_box" v-if="shareInfo.firstThreeItems[0]">
                    <span>{{ shareInfo.firstThreeItems[0].id != 0 ? shareInfo.firstThreeItems[0].address :'?'  }}</span>
                    <span style="color: #000;">{{ shareInfo.firstThreeItems[0].id != 0 ? shareInfo.firstThreeItems[0].addition_cp :''  }}</span>
                </div>
            </div>
            <div class="sharelist_line2_box level3">
                <div class="sharelist_line2_box_box" v-if="shareInfo.firstThreeItems[2]">
                    <span>{{ shareInfo.firstThreeItems[2].id != 0 ? shareInfo.firstThreeItems[2].address :'?'  }}</span>
                    <span style="color: #000;">{{ shareInfo.firstThreeItems[2].id != 0 ? shareInfo.firstThreeItems[2].addition_cp :''  }}</span>
                </div>
            </div>
        </div>
        <div class="sharelist_line3">
            <div class="sharelist_line3_box" v-for="(item, index) in shareInfo.remainItems">
                <div class="sharelist_line3_box_left">
                    <span>{{ index + 4 }}</span>
                    <span>{{ item.address }}</span>
                </div>
                <div class="sharelist_line3_box_right">
                    <span>{{ item.addition_cp }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        shareInfo: {
            type: Object,
            required: true,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less'>
.sharelist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .sharelist_line4{
        margin: 10px;
        font-size: 13px;
        color: #66B4AE;
        background: url('~@/assets/img/sharelist_bg1.png') no-repeat;
        background-size: contain;
        padding: 3px 21px;
    }

    .sharelist_line3 {
        max-height: 212px;
        overflow-y: scroll;
        width: 100%;
        .sharelist_line3_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 16px;
            background: #F7F7F7;
            border-radius: 13px;
            margin-top: 12px;
            color: rgba(0, 6, 31, 0.4);
            font-size: 16px;
            font-weight: bold;
            padding-right: 48px;

            .sharelist_line3_box_left {
                display: flex;
                align-items: center;
                gap: 18px;
            }
        }
    }

    .sharelist_line2 {
        margin-top: 15px;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .level2 {
            color: #687CE3;
            background: url('~@/assets/img/sharelist_img2.png') no-repeat;
            transform: translateY(40px);
        }

        .level1 {
            color: #66B4AE;
            background: url('~@/assets/img/sharelist_img1.png') no-repeat;
        }

        .level3 {
            color: #F3BA2F;
            background: url('~@/assets/img/sharelist_img3.png') no-repeat;
            transform: translateY(40px);
        }

        .sharelist_line2_box {
            font-size: 16px;
            font-weight: bold;
            background-size: cover;
            width: 30%;
            height: 152px;
            display: flex;
            align-items: center;
            justify-content: center;

            .sharelist_line2_box_box {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .sharelist_line1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-size: 20px;
        gap: 7px;
        font-weight: bold;

        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>